<template>
  <div ref="block" class="relative group">
    <div class="relative n-fixes">
      <div
        class="h-8 duration-100 flex items-end justify-end"
        :class="selected ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'"
      >
        <n-config-provider preflight-style-disabled>
          <div class="flex gap-2">
            <n-button-group size="small">
              <n-button secondary size="small" @click="onCopyInPageLink">
                {{ t('admin.copy_in_page_link') }}
              </n-button>
              <n-button secondary size="small" @click="onCopyCode">
                {{ t('admin.copy_code') }}
              </n-button>
            </n-button-group>

            <n-button-group size="small">
              <n-button
                tertiary
                size="small"
                type="success"
                :title="t('admin.make_template')"
                @click="isTemplateCreateModalOpen = true"
              >
                <BaseIcon
                  name="outline_squares_plus"
                  size="xs"
                  class="block sm:hidden"
                />
                <span class="hidden sm:block">
                  {{ t('admin.make_template') }}
                </span>
              </n-button>
            </n-button-group>

            <n-button-group size="small">
              <n-button
                tertiary
                size="small"
                type="info"
                :title="t('admin.edit')"
                @click="select"
              >
                <BaseIcon name="outline_pencil" size="xs" />
              </n-button>
              <n-button
                tertiary
                size="small"
                type="success"
                :title="t('admin.dublicate')"
                @click="emit('dublicate')"
              >
                <BaseIcon name="outline_duplicate" size="xs" />
              </n-button>
              <n-button
                tertiary
                size="small"
                :title="t(`admin.${element.is_visible ? 'hide' : 'show'}`)"
                @click="emit('toggle:visibility')"
              >
                <BaseIcon
                  :name="element.is_visible ? 'outline_eye_off' : 'outline_eye'"
                  size="xs"
                />
              </n-button>
              <n-button
                tertiary
                size="small"
                type="error"
                :title="t('admin.delete')"
                @click="isDeleteModalOpen = true"
              >
                <BaseIcon name="outline_trash" size="xs" />
              </n-button>
            </n-button-group>
            <n-button-group size="small">
              <n-button
                tertiary
                size="small"
                :title="t('admin.up')"
                @click="emit('move:up')"
              >
                <BaseIcon name="solid_arrow_up" size="xs" />
              </n-button>
              <n-button
                tertiary
                size="small"
                :title="t('admin.down')"
                @click="emit('move:down')"
              >
                <BaseIcon name="solid_arrow_down" size="xs" />
              </n-button>
            </n-button-group>
          </div>
        </n-config-provider>
      </div>
      <Teleport to="body">
        <div
          v-if="selected"
          class="editor n-fixes z-20 shadow-md border border-gray-100"
        >
          Edit Block
          <n-config-provider preflight-style-disabled>
            <VueElementForm
              :model-value="element.editorData"
              :schema="element.editorSchema"
              :ui-schema="element.editorUISchema || {}"
              :form-footer="formFooter"
              @submit="onSubmit"
              @cancel="onCancel"
            ></VueElementForm>
          </n-config-provider>
        </div>
        <BaseModal
          :is-open="isIntegrationModalOpen"
          :fullscreen="true"
          size="2xl"
          title="Integration Instructions"
          class="integration-modal"
          @close="isIntegrationModalOpen = false"
        >
          <template #body>
            <div class="p-6">
              <div>{{ t('admin.copy_block_link_for_meeting_system') }}</div>
              <BaseButton size="xs" @click="copyText(blockLink, 'block link')">
                {{ t('admin.copy_block_link') }}
              </BaseButton>
              <div class="overflow-auto text-xs mt-3">
                <pre ref="iframeCodeElement">{{ blockLink }}</pre>
              </div>
              <hr class="my-5" />

              <div>{{ t('admin.copy_and_paste_iframe_code') }}</div>
              <BaseButton size="xs" class="mr-1" @click="onIframeCodeCopy">
                {{ t('admin.copy_iframe_code') }}
              </BaseButton>
              <div class="overflow-auto text-xs mt-3">
                <pre ref="iframeCodeElement">{{ iframeCode(blockLink) }}</pre>
              </div>
              <hr class="my-5" />

              <div>
                {{ t('admin.support_code_description') }}
              </div>
              <BaseButton size="xs" @click="onSupportCodeCopy">
                {{ t('admin.copy_support_code') }}
              </BaseButton>
              <div class="overflow-auto text-xs mt-3">
                <pre ref="supportCodeElement">{{ supportCode }}</pre>
              </div>
              <hr class="my-5" />
              <div class="text-md font-bold">IFrame height</div>
              <div>
                {{ t('admin.iframe_block_description') }}
              </div>
              <BaseButton size="xs" @click="onResizeCodeCopy">
                {{ t('admin.copy_resize_code') }}
              </BaseButton>
              <div class="overflow-auto text-xs mt-3">
                <pre ref="resizeCodeElement">{{ iframeResizeCode }}</pre>
              </div>
            </div>
          </template>
        </BaseModal>
      </Teleport>
      <div
        class="cursor-pointer rounded-xl relative"
        :class="[
          selected
            ? 'outline outline-primary outline-4'
            : 'group-hover:outline group-hover:outline-gray-500 group-hover:outline-2',
        ]"
        @click="select"
      >
        <div
          v-if="!element.is_visible"
          class="bg-primary-50 py-1 px-2 absolute right-0 top-0 rounded-tr-lg rounded-bl-lg z-10 text-gray-500 flex items-center gap-2"
        >
          <div>The block is hidden</div>
          <BaseIcon name="outline_eye_off" size="xs" />
        </div>
        <component
          :is="element.viewComponent"
          :uuid="pageEditorStore.getBlockId(element)"
          :block-id="element.id"
          :block-uuid="element.uuid"
          :form-data="element.editorData"
          class="pointer-events-none"
        />
      </div>
      <div
        class="flex justify-center duration-100 w-full bottom-0 h-8"
        :class="selected ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'"
      >
        <n-config-provider preflight-style-disabled>
          <n-button
            secondary
            size="small"
            type="success"
            :title="t('admin.open_block_menu')"
            @click="emit('add')"
          >
            <BaseIcon name="solid_plus" size="xs" />
          </n-button>
        </n-config-provider>
      </div>
    </div>
    <BaseModal
      v-if="isDeleteModalOpen"
      :title="t('admin.delete_block')"
      :is-open="isDeleteModalOpen"
      size="sm"
      @close="isDeleteModalOpen = false"
    >
      <div class="mb-4">
        {{ t('admin.block_confirm_delete_text') }}
      </div>
      <div class="flex justify-between gap-4">
        <BaseButton
          look="link"
          class="!px-0"
          style="white"
          @click="isDeleteModalOpen = false"
        >
          {{ $t('admin.cancel') }}
        </BaseButton>
        <BaseButton
          theme="primary"
          look="solid"
          type="submit"
          @click="onDelete"
        >
          {{ $t('admin.confirm_delete_title') }}
        </BaseButton>
      </div>
    </BaseModal>
    <TemplateCreateModal
      :is-open="isTemplateCreateModalOpen"
      :block="element"
      @close="isTemplateCreateModalOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { onClickOutside } from '@vueuse/core'
import VueElementForm, { i18n } from '@lljj/vue3-form-naive'
import localizeEn from 'ajv-i18n/localize/en'
import { iframeCode, supportCode, iframeResizeCode } from './integration'
import TemplateCreateModal from './template/TemplateCreateModal.vue'
import { useNotifications } from '~/stores/notifications'
import { usePageEditor } from '~/stores/page-editor'
import type { EditorBlock } from '~/models/page-editor'

i18n.useLocal(localizeEn)

const { pushNotification } = useNotifications()
const { t } = useI18n()
const pageEditorStore = usePageEditor()

const block = ref()
const isIntegrationModalOpen = ref(false)
const selected = ref(false)
const isDeleteModalOpen = ref(false)
const isTemplateCreateModalOpen = ref(false)

interface PropsInterface {
  element: EditorBlock
}

const props = defineProps<PropsInterface>()

const route = useRoute()
const eventSlug = Array.isArray(route.params.event)
  ? route.params.event[0]
  : route.params.event

const emit = defineEmits([
  'select',
  'submit',
  'delete',
  'move:up',
  'move:down',
  'add',
  'dublicate',
  'toggle:visibility',
])

const select = () => {
  selected.value = true
  emit('select')
}

const blockLink = computed(() => {
  return `${useHost()}/iframe/event-info/${eventSlug}/blocks/${
    props.element.uuid
  }`
})

const copyText = async (text: string, entity: string) => {
  try {
    await navigator.clipboard.writeText(text)
    pushNotification({
      title: `The ${entity} was successfully copied to the clipboard`,
    })
  } catch (error) {
    pushNotification({
      title: `Error, the ${entity} was not copied to the clipboard`,
      theme: 'destructive',
    })
    useLogError(new Error('error on clipboard.writeText'))
  }
}

function onCopyCode() {
  isIntegrationModalOpen.value = true
}

function onCopyInPageLink() {
  copyText(
    location.origin +
      location.pathname +
      '#' +
      pageEditorStore.getBlockId(props.element),
    'in-page block link'
  )
}

const iframeCodeElement = ref<HTMLDivElement | null>(null)
const supportCodeElement = ref<HTMLDivElement | null>(null)
const resizeCodeElement = ref<HTMLDivElement | null>(null)

function onIframeCodeCopy() {
  copyText(iframeCodeElement.value?.innerText as string, 'iframe code')
}

function onSupportCodeCopy() {
  copyText(supportCodeElement.value?.innerText as string, 'support code')
}

function onResizeCodeCopy() {
  copyText(resizeCodeElement.value?.innerText as string, 'resize code')
}

onClickOutside(block, () => (selected.value = false), {
  ignore: [
    '.editor',
    '.integration-modal',
    '.n-select',
    '.n-base-select-option',
  ],
})

const formFooter = {
  // show: false,
  okBtnProps: { type: 'primary' },
  okBtn: 'Save',
  cancelBtn: 'Cancel',
}

const onSubmit = (formData: any) => {
  emit('submit', formData, props.element)
  selected.value = false
}

const onCancel = () => {
  selected.value = false
}

const onDelete = () => {
  isDeleteModalOpen.value = false
  emit('delete')
}
</script>

<style lang="scss">
.toolbar {
  position: absolute;
  right: 2px;
  bottom: calc(100% + 5px);
}

.editor {
  padding: 100px 10px 10px;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  max-width: 640px;
  height: 100%;
  overflow: auto;
}

.n-fixes {
  svg {
    display: inline;
  }

  .n-button {
    background-color: var(--n-color);
  }
}

.toastui-editor-popup-add-link {
  left: 0 !important;
  width: 240px;
}
</style>
